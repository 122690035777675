<template>
  <div>
    <MoozumiEmail></MoozumiEmail>
  </div>
</template>
<script>
import MoozumiEmail from '../../components/MoozumiEmail.vue'

export default {
  components: { MoozumiEmail }
}
</script>
